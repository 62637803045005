import { useTranslation } from "react-i18next";


const BankCard = ({ card }) => {
    const { t } = useTranslation("common")

    const showLogo = () => {
        switch (card?.currency) {
            case 'VND':
                return "assets/flags/VN.png"
            case 'AUD':
                return "assets/flags/AU.png"
            case 'USD':
                return "assets/flags/US.png"
            default:
                break;
        }
    }

    const showBank = () => {
        switch (card?.currency) {
            case 'VND':
                return 'VPB'
            case 'AUD':
                return 'ANZ'
            default:
                break;
        }
    }

    return (
        <div className="bg-white flex justify-center items-center">
            <div className="space-y-4">
                <div className="w-60 h-36 m-auto rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-105">
                    {card && <img className="relative object-cover w-full h-full rounded-xl " src="https://i.imgur.com/kGkSg1v.png" alt="" /> }
                    
                    <div className="w-full px-4 absolute top-6">
                        <div className="flex justify-between">
                            <div className="">
                            <p className="font-light text-xs">
                                {t("FINANCE.CARD_NUMBER")}
                            </p>
                            <p className="truncate font-medium tracking-more-wider text-sm">
                                {card?.accountNumber}
                            </p>
                                
                            </div>
                            {card && <img className="w-14 h-10 rounded-md" src={showLogo()} alt="" />}
                        </div>
                        <div className="pt-1 text-xs">
                            <p className="font-light">
                                {t("FINANCE.NAME")}
                            </p>
                            <p className="truncate font-medium tracking-widest">
                                {card?.bankAccountName}
                            </p>
                        </div>

                        <div className="pt-1 flex items-end">
                            <p className="font-light text-xs">
                                {t("FINANCE.BANK")}
                            </p>
                            <p className="font-medium tracking-more-wider ml-2">
                                {showBank()}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BankCard;