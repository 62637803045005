import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import { ArrowDown } from 'assets/icons/ArrowDown'
import { t } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from "react-hook-form";
import Select, { StylesConfig } from "react-select";
import { customSelectStyle } from './utils'
import { ErrorMessage } from '@hookform/error-message'

export default function International() {
  const {t} = useTranslation("common")
  const {
    watch,
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = (val: any) => {
    console.log('val: ', val)
   
  }
  return (
    <form className=' w-full min-h-[58vh] max-h-[58vh] overflow-auto' onSubmit={handleSubmit(onSubmit)}>
      <div className=" w-full items-end  h-full">
        <label className=' text-xs mr-3' htmlFor="bank">{t("FINANCE.YOUR_SEND_EXACTLY")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="bank"
          //@ts-ignore
          {...register('bank', {
            required: {
              value: true,
              message: `${t("FINANCE.YOUR_SPAN_EXACTLY")}`
            },
          })}
          render={(field) => {
            return (
              <Select
                className=" font-normal text-xs mx-2"
                styles={customSelectStyle}
                hideSelectedOptions={false}
                value={field.value || []}
                options={[]}
                closeMenuOnSelect={true}
                menuPlacement="bottom"
                placeholder={t("FINANCE.CHOOSE_A_BANK")}
                maxMenuHeight={200}
                onChange={(val) => {
                  field.onChange(val)
                }}
                getOptionLabel={(option) => `${option.name} - ${option.short_name}`}
                getOptionValue={(option) => option.id}
              />
            )
          }}
        />
        <ErrorMessage
          className="text-red-500 text-xs"
          errors={errors}
          name="bank"
          as="p"
        />
      </div>
      
      <div className='px-4 mt-2 text-xs'>
        <div className='flex justify-between'>
          <p>- 10.36 AUD</p>
          <p className=' text-[#bababa]'>Our fee</p>
        </div>
        <div className='flex justify-between mt-2 pb-4 border-b border-[#cdcdcd]'>
          <p>- 10.36 AUD</p>
          <p className=' text-[#bababa]'>{t("FINANCE.TOTAL_FEE")}</p>
        </div>

        <div className='flex justify-between mt-3'>
          <p>= 989.64 AUD</p>
          <p className=' text-[#bababa]'>{t("FINANCE.TOTAL_AMOUT_WELL_CONVERT")}</p>
        </div>

        <div className='flex justify-between mt-2'>
          <p>x 15.842 AUD</p>
          <p className=''>{t("FINANCE.GUARANTEED_RATE")}(84h)</p>
        </div>

      </div>

      <div className="w-full items-end mt-3h-full mt-3">
        <label className=' text-xs mr-3' htmlFor="bank">{t("FINANCE.RECIPIENT_GETS")}</label>
        {/* @ts-ignore */}
        <Controller
          control={control}
          name="bank"
          //@ts-ignore
          {...register('bank', {
            required: {
              value: true,
              message: `${t("FINANCE.REQUIRED")}`
            },
          })}
          render={(field) => {
            return (
              <Select
                className=" font-normal text-xs mx-2"
                styles={customSelectStyle}
                hideSelectedOptions={false}
                value={field.value || []}
                options={[]}
                closeMenuOnSelect={true}
                menuPlacement="bottom"
                placeholder={t("FINANCE.RECIPIENT_GETS")}
                maxMenuHeight={200}
                onChange={(val) => {
                  field.onChange(val)
                }}
                getOptionLabel={(option) => `${option.name} - ${option.short_name}`}
                getOptionValue={(option) => option.id}
              />
            )
          }}
        />
        <ErrorMessage
          className="text-red-500 text-xs"
          errors={errors}
          name="bank"
          as="p"
        />
        <div className='mt-3 px-2 text-xs'>
          <p> {t("FINANCE.YOU_COULD_SAVE_UP_TO")} <strong>38.15 AUD {t("FINANCE.VS_THE_AVERAGE_BANK")} <strong className='underline'>{t("FINANCE.COMPLETE_PRICE")}</strong></strong></p> 
          <p>Should arrive <strong>in 40 minutes</strong></p>         
        </div>

        <div className='w-full flex justify-center'>
          <button className=' bg-green-500 text-white font-bold px-4 py-3 rounded-xl mt-4'>{t("FINANCE.CONTINUE")}</button>
        </div>

      </div>
    </form>
  )
}
