import Table from 'components/Table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {FolderArrowDownIcon} from '@heroicons/react/20/solid'

export default function BillingHistory() {
  const {t} = useTranslation("common")
  return (
    <div className='mt-8 rounded-xl shadow-sm bg-white py-6 overflow-scroll'>
      <div className='flex justify-between'>
        <div>
          <div className='flex items-center px-6'>
            <span className='font-bold text-xl mr-2'>{t("SUBSCRIPTION.BILLING_HISTORY")}</span>
          </div>
          <div className=' text-sm text-[#7c7979] px-6 mt-6'>{t("SUBSCRIPTION.BILLING_HISTORY_CONTENT")}</div>
        </div>
        <div className="border h-fit m-2 bg-green-500  rounded-lg px-3 py-2 flex items-center w-fit"><FolderArrowDownIcon color="#ffffff" width={24} height={24} /> <span className=" text-xs mr-1 text-white">{t("SUBSCRIPTION.DOWNLOAD")}</span></div>
      </div>
      <Table />

    </div>
  )
}
