import { PlusCircleIcon } from '@heroicons/react/20/solid'
import BankCard from 'components/ATMCard/ATM'

interface IMyCards {
  accounts: any
  onAddAccountModal: () => void
}

export default function MyCards({ accounts, onAddAccountModal }: IMyCards) {
  return (
    <div className="p-6 bg-white rounded-xl mt-6">
      <div className="text-lg font-semibold ">Bank Cards</div>
      <div className='flex gap-4 flex-wrap justify-center py-6'>
        {
          (accounts.length > 0) && accounts.filter(account => account !== null).map((card, index) => {
            // if(card)
            return (
              <div key={index} className="w-fit h-fit">
                <BankCard card={card} />
              </div>
            )
          })
        }
        {
          [...Array(4 - accounts.filter(account => account !== null).length)].map(blankCard => {
            return <BlankCard key={blankCard} onAddAccountModal={onAddAccountModal} />
          })
        }
      </div>
    </div>
  )
}

const BlankCard = ({ onAddAccountModal }) => {
  return (
    <div className=" w-fit h-fit bg-white flex justify-center items-center">
      <div className="space-y-4">
        <div className="w-60 h-36 border-dashed border-2 bg-slate-100 border m-auto  rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-105">
          <div className="flex justify-center items-center h-full cursor-pointer" onClick={onAddAccountModal}>
            <PlusCircleIcon className="w-16 h-16 text-slate-500" />
          </div>
        </div>
      </div>
    </div>
  )
}