import { useTranslation } from 'react-i18next'
import Table from './Table'
import Select from "react-select";
import { customSelectStyle } from '../utils'
import { END_POINT_V2 } from "api/endpoint"
import { client } from "api/client"
import { useEffect, useState } from 'react';
import LineChart from './LineChart'
import moment from 'moment';
import { numberWithCommas } from 'utils/number';
import { Spinner } from 'baseui/spinner';
interface IMyTransaction {
  transactionHistories: any
  accounts: any
}

let mapReduce = (arr: any, mapKey: any, reduceKey: any) => {
  let result = [];
  return arr.reduce(function (res, value) {
    if (!res[value[mapKey]]) {
      res[value[mapKey]] = 0
      //  { Id: value[mapKey], qty: 0 };
      result.push(res[value.Id])
    }
    res[value[mapKey]] += value[reduceKey];
    return res;
  }, {});

}

export default function MyTransaction({ accounts }: IMyTransaction) {
  const { t } = useTranslation("common")
  const [transactionHistories, setTransactionHistories] = useState<any>([])
  const [income, setIncome] = useState<any>([])
  const [expense, setExpense] = useState<any>([])
  const [currency, setCurrency] = useState<any>('')
  const [incomeExpenseLoading, setIncomeExpenseLoading] = useState<any>(false)
  const [dataChartLabel, setDataChartLabel] = useState<any>([])
  const [incomeExpenseDataSet, setIncomeExpenseDataSet] = useState<any>([])

  useEffect(() => {
    let result = []
    let tempMoment = moment().subtract(9, 'day');
    for (let i = 1; i <= 10; i++) {
      result.push(tempMoment.clone().format('DD/MM/YYYY'))
      tempMoment.add(1, 'day')
    }
    setDataChartLabel([...result])
  }, [])

  const getTransactionHistories = (currency: string) => {
    client.get(`${END_POINT_V2.GET_TRANSACTIONS}?type=${currency}`)
      .then(res => {
        if (res.data?.errorCode === '00') {
          setTransactionHistories(res.data?.data?.map(item => {
            return {
              ...item,
              amount: currency !== 'VND' ? item?.amount / 100 : item?.amount,
              metadata: {
                withdraw_fee: currency !== 'VND' ? +item?.metadata?.withdraw_fee || 0 / 100 : +item?.metadata?.withdraw_fee || 0,
              }
            }
          }))
        }
      })
  }

  const getIncomeAndExpense = (currency: string) => {
    setIncomeExpenseLoading(true)
    client.get(`${END_POINT_V2.GET_TRANSACTIONS}?type=${currency}&limit=40`)
      .then(res => {
        if (res.data?.errorCode === '00') {
          let tempIncome = res.data?.data.filter((item: any) => +item?.amount >= 0).map((item: any) => {
            return {
              amount: item?.amount,
              created: moment.unix(item.created).startOf('day').format("DD/MM/YYYY")
            }
          });
          let tempExpense = res.data?.data.filter((item: any) => +item?.amount < 0).map((item: any) => {
            return {
              amount: item?.amount,
              created: moment.unix(item.created).startOf('day').format("DD//MM/YYYY")
            }
          })
          setIncome(tempIncome.reduce((a, b) => a + (b.amount), 0))
          setExpense(tempExpense.reduce((a, b) => a + (b.amount), 0))

          const incomeMapReduce = mapReduce(tempIncome, 'created', 'amount');
          const expenseMapReduce = mapReduce(tempExpense, 'created', 'amount');

          let tempDataSet = [
            {
              label: 'Total Income',
              data: dataChartLabel.map((item: any) => {
                return currency !== 'VND' ? (incomeMapReduce[item] || 0) / 100 || 0 : incomeMapReduce[item] || 0
              }),
              borderColor: 'rgb(255, 99, 132)',
              backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
              label: 'Total Expense',
              data: dataChartLabel.map((item: any) => {
                return currency !== 'VND' ? (expenseMapReduce[item] || 0) / 100 || 0 : expenseMapReduce[item] || 0
              }),
              borderColor: 'rgb(53, 162, 235)',
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
          ];


          setIncomeExpenseDataSet(tempDataSet)
        }
        setIncomeExpenseLoading(false)
      })
  }

  return (
    <div>
      <div className="p-6 bg-white rounded-xl grid grid-cols-12">
        <div className=" col-span-12 xl:col-span-5 xl:border-r border-[#f4f4f4]">
          <div>
            <div className=" text-sm text-[#b0adad]">{t('FINANCE.TOTAL_INCOME')}</div>
            <div className=" text-2xl font-semibold mt-2">{incomeExpenseLoading ? <Spinner size={15} /> : `${numberWithCommas(currency !== 'VND' ? income / 100 : income || 0)}${currency}`}</div>
            {/* <div className=" flex mt-2">
              <span className=" text-xs flex text-[#2db94e]"><ArrowUpIcon width={14} height={14} color="#2db94e"/> +12.1%</span>
              <span className="text-sm text-[#a4a1a1] text-xs ml-2">form last week</span>
            </div> */}
          </div>
          <div className="mt-10">
            <div className=" text-sm text-[#b0adad]">{t('FINANCE.TOTAL_EXPENSE')}</div>
            <div className=" text-2xl font-semibold mt-2">{incomeExpenseLoading ? <Spinner size={15} /> : `${numberWithCommas(currency !== 'VND' ? expense / 100 : expense || 0)}${currency}`}</div>
          </div>
        </div>
        <div className="hidden xl:block col-span-7 px-4">
          <LineChart
            labels={dataChartLabel}
            datasets={incomeExpenseDataSet} />
        </div>
      </div>
      <div className="p-6 bg-white rounded-xl mt-6">
        <div className=' rounded-xl shadow-sm bg-white'>
          <div className='xl:flex justify-between'>
            <div>
              <div className='mb-4 xl:mb-0'>
                <div className="font-bold text-xl text-left">{t("FINANCE.MY_TRANSACTION")}</div>
              </div>
            </div>
            <Select
              placeholder='Choose account'
              className="font-normal text-xs"
              isSearchable={false}
              styles={customSelectStyle}
              hideSelectedOptions={false}
              options={accounts.filter(account => account !== null)}
              closeMenuOnSelect={true}
              menuPlacement="auto"
              maxMenuHeight={200}
              getOptionLabel={(option: any) => {
                return option?.currency
              }}
              getOptionValue={(option: any) => option?.accountNumber}
              onChange={(val: any) => {
                setCurrency(val?.currency)
                getTransactionHistories(val?.currency);
                getIncomeAndExpense(val?.currency);
              }}
            />
          </div>
          <Table data={transactionHistories} />
        </div>
      </div>
    </div>
  )
}
