import { client } from 'api/client'
import { END_POINT_V2 } from 'api/endpoint'
import { Plus } from 'assets/icons/Plus'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Plan({
  profile = null,
  setShowPlanSubscriptions = (value) => { }
}
) {
  const { t } = useTranslation("common")
  const [products, setProducts] = useState<any>([])

  useEffect(() => {
    client.get(`${END_POINT_V2.GET_PRODUCTS_PLAN}?limit=10`)
      .then(res => setProducts(res.data.data))
  }, [])

  console.log(profile?.stripe?.plans[0])

  return (
    <>
      <div className='border-b border-[#f4f4f4] p-4 md:p-6 grid grid-cols-3'>
        <div>
          <div className='flex items-center'>
            <span className='font-bold text-xl mr-2 uppercase'>{profile?.stripe?.plans[0]?.name || "N/A"}</span>
            {/* <div className=' rounded-full border p-2 text-sm font-semibold'> • {t("SUBSCRIPTION.ACTIVE")}</div> */}
          </div>
          <div className=' text-sm text-[#7c7979]'>{t("SUBSCRIPTION.PLAN_CONTENT")}</div>

        </div>
        <div className=' py-4 text-center'>
        </div>
        <div className=' text-sm text-[#bababa]'>
        <div className=''>
          <span className='text-xl text-[#333333]'>{
            !profile?.stripe?.plans[0]?.price ? "Free" : `${profile?.stripe?.plans[0]?.price?.unit_amount}${profile?.stripe?.plans[0]?.price?.currency}`
          }</span>

            {profile?.stripe?.plans[0]?.price && <div className=''>{t("SUBSCRIPTION.PER_MONTH")}
            </div>}
          </div>
          <div>
            App Fee: {!profile?.stripe?.plans[0]?.price ? process.env.REACT_APP_APP_FEE :
              `${profile?.stripe?.plans[0]?.metadata?.app_fee}${profile?.stripe?.plans[0]?.price?.currency}`}
          </div>
          <div>
            Product Fee: {!profile?.stripe?.plans[0]?.price ?
              process.env.REACT_APP_PRODUCT_FEE :
              `${profile?.stripe?.plans[0]?.metadata?.product_fee}${profile?.stripe?.plans[0]?.price?.currency}`}
          </div>
          <div>
            Withdraw Fee: {!profile?.stripe?.plans[0]?.price ?
              process.env.REACT_APP_WITHDRAW_FEE :
              `${profile?.stripe?.plans[0]?.metadata?.withdraw_fee}${profile?.stripe?.plans[0]?.price?.currency}`
            }
          </div>
        </div>
      </div>
      <div className=' p-6 flex justify-between'>
        <button
          onClick={() => { setShowPlanSubscriptions(true) }}
          type='button' className=' shadow-sm rounded-lg w-fit border p-2 px-3 text-sm font-medium bg-green-500 text-white font-semibold'>{t("SUBSCRIPTION.UPGRADE_PLAN")}</button>
      </div>
    </>
  )
}
