
import { useTranslation } from 'react-i18next'

export default function PaymentMethod() {
  const {t} = useTranslation('common')
  return (
    <div>
      <div className=' '>
        <span className='font-bold text-xl mr-2'>{t("SUBSCRIPTION.PAYMENT_METHOD")}</span>
        <div className=' text-sm text-[#7c7979]'>{t("SUBSCRIPTION.PAYMENT_METHOD_CONTENT")}</div>
      </div>
      <div className=' w-full rounded-xl border border-[#f4f4f4] p-4 flex justify-between mt-4'>
        <div>
          <div></div>
          <div>
            <div className='flex items-center'>Visa ending in 1234 <div className='ml-3 rounded-lg w-fit  border border-green-500 text-green-500 p-2 text-sm font-semibold'>{t("SUBSCRIPTION.DEFAULT")}</div></div>
            <div className='text-sm text-[#7c7979]'>Expiry 06/2024</div>
            <div className='text-sm text-[#7c7979]'>billing@untititledui.com</div>
          </div>
        </div>
        <div className=' rounded-lg bg-green-500 text-sm text-white font-semibold px-3 py-2 w-fit h-fit'>{t("SUBSCRIPTION.EDIT")}</div>
      </div>
    </div>
  )
}
