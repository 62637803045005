import { client } from "api/client";
import { END_POINT_V2 } from "api/endpoint";
import { Spinner } from "baseui/spinner";
import { useState } from "react";
import Select from "react-select";
import { numberWithCommas } from "utils/number";
import { customSelectStyle } from '../utils';

interface iStatistic {
  accounts: any
  setShowTransactionPopup: Function
}

export default function Statistic({ accounts = [], setShowTransactionPopup }: iStatistic) {
  const [currency, setCurrency] = useState<any>('VND');
  const [loading, setLoading] = useState<any>(false);
  const [currentBalance, setCurrentBalance] = useState(0)

  const getBalance = (currency: string) => {
    setLoading(true)
    setCurrency(currency)
    client.get(`${END_POINT_V2.GET_BALANCE}?type=${currency}`)
    .then(res => {
      setCurrentBalance(res.data?.data?.balance) 
      setLoading(false)
    })
  }
  
  return (
    <div className='p-6 bg-white rounded-xl '>
      <div>
        <div className='flex justify-between'>
          <div className=" text-sm text-[#bababa]">Total Balance</div>
          <Select
            placeholder='Choose account'
            className="font-normal text-xs"
            isSearchable={false}
            styles={customSelectStyle}
            hideSelectedOptions={false}
            options={accounts.filter(account => account !== null)}
            closeMenuOnSelect={true}
            menuPlacement="auto"
            maxMenuHeight={200}
            getOptionLabel={(option: any) => {
              return option?.currency
            }}
            getOptionValue={(option: any) => option?.accountNumber}
            onChange={(val: any) => {
              getBalance(val?.currency)
            }}
          />
        </div>
        <div className=" text-2xl font-semibold mt-2">{
          loading? <Spinner size={15}/> :
          numberWithCommas(currency !== 'VND' ? +currentBalance / 100 : +currentBalance || 0)}{currency}</div>
        </div>
      <div className="grid grid-cols-2 gap-4 mt-3">
        <button className="col-span-1 text-white font-medium py-2 rounded-lg text-center bg-blue-400 mt-3" onClick={() => setShowTransactionPopup(true)}>Transfer</button>
        <button className="col-span-1 text-white font-medium py-2 rounded-lg text-center bg-blue-400 mt-3">Request</button>
      </div>
    </div>
  )
}
