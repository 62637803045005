import react, { Fragment, useRef } from 'react'
import { client } from 'api/client'
import { END_POINT_V2 } from 'api/endpoint'
import { Plus } from 'assets/icons/Plus'
import { Spinner } from 'baseui/spinner'
import { AuthContext } from 'context/auth'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Plan from './Plan'
import BillingHistory from './BillingHistory'
import PaymentMethod from './PaymentMethod'
import { numberWithCommas } from 'utils/number'
import {
  Elements,
  PaymentElement,
  useStripe,
   useElements,
   CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Transition,
  Dialog
} from '@headlessui/react'
import { useAlert } from 'react-alert'
import { Eyes } from 'assets/icons/Eyes'
import { EyeDropperIcon, EyeIcon } from '@heroicons/react/20/solid'

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_ELEMENT_STRIPE_KEY);

const ModalCardStripe = ({
  clientSecret = null,
  onClose = () => { },
}) => {
  const [submitting, setSubmitting] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert()
  const { t } = useTranslation("common")


  const onSubmit = async (event: any)=>{
     // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    console.log(elements, stripe)
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return alert.error(t('FINANCE.ADD_CARD_ERROR'));
    }
    setSubmitting(true)

    const result = stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        },
        setup_future_usage: 'off_session'
      })
      .then(function (result) {
        if (result?.paymentIntent?.status) {
          alert.success(t('FINANCE.ADD_CARD_SUCCESS'))
          return setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
        alert.error(t('FINANCE.ADD_CARD_ERROR'))
        // Handle result.error or result.paymentIntent
      })
      .catch(e => {
        alert.error(t('FINANCE.ADD_CARD_ERROR'))
      })
      .finally(() => {
        onClose()
        setSubmitting(false)
      });
  }

  return (<Transition appear show={clientSecret !== null} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={() => { }}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Input your payment method
              </Dialog.Title>
              {clientSecret &&  <form onSubmit={onSubmit}> 
                <CardElement />
                <button disabled={submitting}>{submitting ? 'Submitting' : 'Submit'}</button>
              </form>
              }

            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>)
}

const SubscriptionPlanView = ({
  subscriptionPlans = [],
  update = false,
  setShowSubscriptionPlanView
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [currentPlan, setCurrentPlan] = useState(null)
  const [clientSecret, setClientSecret] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)

  useEffect(() => {
    if (subscriptionPlans.length > 0) {
      setSelectedPlan(subscriptionPlans.find((plan: any) => !plan.price));
      setCurrentPlan(subscriptionPlans.find((plan: any) => !plan.price));
    }

  }, [subscriptionPlans]);

  const submit = () => {
    setSubmitting(true)
    if (!update) {
      if (!selectedPlan.price)
        client.post(END_POINT_V2.CREATE_FREE_SUBSCRIPTION, {})
        .finally(()=>{
          setSubmitting(false)
        })

      client.post(END_POINT_V2.CREATE_SUBSCRIPTION, {
        priceId: selectedPlan?.price?.id
      }).then(res => {
        setClientSecret(res.data?.data?.clientSecret)
      })
      .finally(()=>{
        setSubmitting(false)
      })

      return;
    }

    client.patch(END_POINT_V2.UPDATE_SUBSCRIPTION, {
      priceId: selectedPlan?.price?.id
    })
    .finally(()=>{
      setSubmitting(false)
    })
  }
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <React.Fragment>
      <div className='mt-4 p-6 bg-white flex items-center justify-start rounded-lg mb-6 relative'>
        <div className=' absolute right-4 top-4 border rounded-full text-xs cursor-pointer px-3 py-2 border-[#bababa] font-semibold flex jusify-center items-center' 
          onClick={() => setShowSubscriptionPlanView(false)}
        >
          <EyeIcon />
          <span>Hide</span>
        </div>
        <div className='w-3/4'>
          <div className='font-bold text-xl mr-2'> {update ? 'Update your plan' : 'Choose your plan'}</div>
          <div className='grid grid-cols-3 gap-6 mt-6 gap-4  m-auto'>
            {
              subscriptionPlans.map((plan: any, index: number) => {
                return (
                  <div key={index} className={` col-span-3 md:col-span-1 rounded-lg shadow-sm hover:shadow-xl p-4 text-white ${selectedPlan?.id === plan.id ? ' bg-blue-400' : ' hover:border-blue-500 border border-blue-400 text-black bg-white '} `}>
                    <div className='capitalize font-semibold text-md'>{plan.name}</div>
                    <div className='text-slate-400 text-sm'>{plan.statement_descriptor}</div>
                    <div className='mt-4 text-xl font-bold'>{!plan?.price ? "Free" : `$${numberWithCommas(+(plan?.price?.unit_amount / 23000).toFixed(2).replace(",", ""))
                      }`}</div>
                    <div>
                      <span className={`text-slate-400 ${!plan.price ? 'invisible' : 'block'}`}>
                        /month
                      </span>
                    </div>
                    <div className='text-center'>
                      <button type='button' className={`border text-semibold rounded-lg w-fit px-3 py-2 ${selectedPlan?.id === plan.id ? "bg-whi" : "bg-blue-400 border-0 text-white"}`} 
                      onClick={() => {
                        if (currentPlan?.id !== plan.id) {
                          setOpenModalConfirm(true)
                          setSelectedPlan(plan)
                        }                        
                      }}>
                        {currentPlan?.id === plan.id ? 'Current Plan' : 'Try it now'}
                      </button>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* I moved the action click submit into the button click to select a plan, I think it works with better UX. however I am sure your logic still works, thank you! */}
        </div>
        
      </div>
      {
        clientSecret && (
          <Elements
            stripe={stripePromise} options={options}>
            <ModalCardStripe
              clientSecret={clientSecret}
              onClose={() => {
                setClientSecret(null)
              }} />
          </Elements>)
      }
      <Transition appear show={openModalConfirm} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpenModalConfirm(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                   Confirm
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure upgrade your plan?
                    </p>
                  </div>

                  <div className="mt-4 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        submit()
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </React.Fragment>
  )
}

export default function Subscription() {
  const { t } = useTranslation("common")
  const { profile, isAuthenticated } = useContext(AuthContext);
  const [loading, setLoading] = useState(true)
  const [showSubscriptionPlanView, setShowSubscriptionPlanView] = useState(false)
  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [showUpdateSubscriptionPlanView, setShowUpdateSubscriptionPlanView] = useState(false)

  const getProductPlansWithPrice = async (update = false) => {
    const productPlans = await client.get(`${END_POINT_V2.GET_PRODUCTS_PLAN}?limit=10`).then(res => res.data.data)
    const productPrice = await client.get(`${END_POINT_V2.GET_PRODUCTS_PRICE}?limit=10`).then(res => res.data.data)
    setSubscriptionPlans(productPlans.map((plan: any) => {
      plan.price = productPrice.find((price: any) => price.id === plan.default_price)
      return plan
    })?.reverse()
    )
    setLoading(false)
    setShowSubscriptionPlanView(true)
    if (update) setShowUpdateSubscriptionPlanView(true);
  }

  useEffect(() => {
    if (!profile.main)
      return;

    if (profile.stripe) {
      if (profile.stripe.stripeId)
        client.get(`${END_POINT_V2.GET_INVOICE}?limit=10`)
          .then(res => {

          });

      setLoading(false)
      return;
    }

    if (!profile.stripe) {
      getProductPlansWithPrice()
    }
  }, [profile])

  // if (loading)
  //   return ( */}
    // </div>)

  return loading ? 
    (
      <div className="container mx-auto py-10 px-4 lg:px-0 min-h-[90vh] flex justify-center items-center">
          <Spinner size={30} />
      </div>
    ) 
    : 
    (
      <>
        <div className="hero overflow-auto">
          <div className="container mx-auto py-10 px-4 lg:px-0">
            <div>
              <h2 className=' text-2xl font-bold'>
                {t("SUBSCRIPTION.PLAN_AND_BILL")}
              </h2>
              <p>{t("SUBSCRIPTION.PLAN_AND_BILL_CONTENT")}</p>
            </div>

            <div className='grid grid-cols-2 gap-6 mt-10'>
              <div className=' col-span-2 lg:col-span-1 rounded-lg shadow-sm border border-[#f5f5f5] bg-white'>
                <Plan
                  profile={profile}
                  setShowPlanSubscriptions={getProductPlansWithPrice}
                />
              </div>

              <div className=' col-span-2 lg:col-span-1 rounded-lg shadow-sm border border-[#f5f5f5] bg-white p-6'>
                <PaymentMethod />
              </div>
            </div>

            {
              showSubscriptionPlanView &&
              <SubscriptionPlanView
                subscriptionPlans={subscriptionPlans}
                update={showUpdateSubscriptionPlanView} 
                setShowSubscriptionPlanView={setShowSubscriptionPlanView}
              /> 
            }

            {
              profile?.stripe?.stripeId && <div>
                <BillingHistory />
              </div>
            }
          </div>
        </div>
      </>
    )
}
