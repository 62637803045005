import React, { useEffect, useRef, useState } from 'react';
import { client, setToken } from 'api/client';
import { BASE_API_URL_V2, END_POINT_V2, END_POINT_V3, END_POINT_V4 } from 'api/endpoint';
import { useAlert } from "react-alert";
import io from 'socket.io-client';
import moment from 'moment';
import axios from 'axios';
type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  authenticateV2: Function;
  signOut: Function;
  verifyOTPSuccess: Function,
  profile: any;
  notifications: any[]
  onClearNoti: Function,
  token: string,
  ip: any
};

export const AuthContext = React.createContext({} as AuthProps);
const AuthProvider = (props: any) => {
  const wsClientRef = useRef<any>()
  const [token, setAuthToken] = useState(localStorage.getItem('epis-token'));
  const [isAuthenticated, makeAuthenticated] = useState(token ? true : false);
  const [profile, setProfile] = useState({});
  const [notifications, setListNotifications] = useState([])
  const [loginInfoV2, setLoginInfoV2] = useState(null)
  const [ip, setIP] = useState(null)
  const alert = useAlert();
  useEffect(() => {
    const getData = async () => {
      // cn
      // fetch('https://ip-detect.myepis.cloud/',{
      //   method: "GET",
      //   mode: 'no-cors'
      // })
      // .then(res =>{
      //   console.log(res)
      //   res.text()})
      //   .then(data => {)

      const a = await axios.get('https://ip-detect.myepis.cloud/',
        {
          headers: null
        }
      ).then(e => {
        return e.data
      }).catch(e => {
        return e.response.data
      })

      setIP(a)



      // .then(res => res.json())
      // .then(data => {
      //   console.log(data)
      // })
      // console.log(res.data);
      // setIP(res.data.IPv4)
    }

    getData()
    if (token) {
      client
        .get(`${END_POINT_V4.PROFILE}`)
        .then((result) => {
          if (result.status === 200) {
            setProfile(result.data)

            makeAuthenticated(true)
          }
          else {
            alert.error(result?.data?.message)
            localStorage.removeItem('epis-token');
            makeAuthenticated(false)
          }
        })
        .catch((error) => {
          alert.error(error?.response?.data?.message)
          localStorage.removeItem('epis-token');
          makeAuthenticated(false)
        });
    }
  }, [token]);

  useEffect(() => {
    if (!token) return;
    wsClientRef.current = io(BASE_API_URL_V2, {
      query: { token },
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
    })

    wsClientRef.current.on('connect', () => {
      console.log('connect: ')
      wsClientRef.current.emit('join_room', { room: ['payment_room'] })
    })

    wsClientRef.current.on('payment_transfer_success', (data) => {
      console.log('data: ', data)
      setListNotifications([{ ...data, time: moment()?.format("DD/MM/YYYY HH:mm") }, ...notifications])
    });

    wsClientRef.current.on('connect_error', function (data) {
      console.log(data)
    })

    wsClientRef.current.on('disconnect', (data) => {
      console.log({ data })
    })
    // cleanup function to disconnect from the server when the component unmounts
    return () => {
      wsClientRef.current.off('connect')
      wsClientRef.current.off('disconnect')
      wsClientRef.current.off('payment_transfer_success')
    };
  }, [token]);

  async function authenticate({ phone }, setErrorRequestMsg, setSubmitting, setLoginInfo, setOpenModalVerifyCode) {
    await client
      .post(`${END_POINT_V4.LOGIN}`, { phoneNumber: phone })
      .then((result) => {
        if (result.status === 201) {
          setLoginInfo(result.data)
          if (result.data?.key?.length)
            setOpenModalVerifyCode(true)
          else {
            localStorage.setItem('epis-token', result.data?.data?.token);
            const token = result.data?.data?.token
            setToken(token);
            setAuthToken(token);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          setErrorRequestMsg(error.response.data.message)
          setSubmitting(false)
        }
      });
  }

  async function authenticateV2({
    phone = '',
    password = '',
    setSubmitting = (e: any) => { },
    onFailed = (e) => { },
    onSuccess = (e) => { },
    onVerifyCode = (e) => { },
  }) {
    setSubmitting(true)
    await client
      .post(`${END_POINT_V2.LOGIN}`, { phone, password })
      .then((result) => {
        if (result.status === 200) {
          const token = result.data?.data?.token
          setToken(token);
          setAuthToken(token);
          if (result.data?.data?.message === 'Please verify phone number')
            return onVerifyCode({
              type: true,
              data: result.data?.data?.data
            })

          onVerifyCode(false)
          setLoginInfoV2(result.data?.data)
          localStorage.setItem('epis-token', result.data?.data?.token);
          onSuccess(result?.data?.message)
        }
        else {

        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          onFailed(error.response.data.message)
          // setErrorRequestMsg(error.response.data.message)
          // setSubmitting(false)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  function signOut(cb) {
    localStorage.removeItem('epis-token');
    makeAuthenticated(false)
    setTimeout(cb, 100);
  }

  async function verifyOTPSuccess({ accessToken }) {
    localStorage.setItem('epis-token', accessToken);
    setToken(accessToken)
    makeAuthenticated(true)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        authenticateV2,
        verifyOTPSuccess,
        signOut,
        profile,
        notifications,
        onClearNoti: setListNotifications,
        token: token,
        ip: ip
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
