import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
const BaseModal = ({ children, ...props }) => {
  return (
    <Modal
      {...props}
      animate
      autoFocus
      size={props.size || SIZE.default }
      role={ROLE.dialog}
    >
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      {
        !props.hideFooter && <ModalFooter>
        <ModalButton onClick={() => props.action()}>{props.buttonText}</ModalButton>
      </ModalFooter>
      }
    </Modal>
  );
}
export default BaseModal