import { Tab } from '@headlessui/react'
import International from "./International"
import Domestic from "./Domestic"
import React, { Fragment, useContext, useEffect, useState } from "react"
import Select, { StylesConfig } from "react-select";
import {
  Transition,
  Dialog
} from '@headlessui/react'
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from "@hookform/error-message"
import { client } from "api/client"
import { END_POINT_V2 } from "api/endpoint"
import { useAlert } from "react-alert";
import { numberWithCommas } from "utils/number"
import Statistic from "./components/Statistic"
import MyCards from "./components/MyCards"
import MyTransaction from "./components/MyTransaction"
import { ArrowLongLeftIcon } from '@heroicons/react/20/solid';
import { AuthContext } from 'context/auth';
//@ts-ignore
import { useHistory } from "react-router-dom";

const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, color: "#2E3A59", marginRight: 12 }),
  control: (styles) => ({
    ...styles,
    borderColor: '#EBEBF0',
    minHeight: '40px',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px',
    fontSize: 12
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#F2F9FF',
    borderRadius: '18px',
    textColor: '#2E3A59',
    fontWeight: 400,
  }),
}

const dataTemp = [
  null, null, null, null
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const countries = [
  { value: 1, label: 'Vietnam', currency: 'VND', countryCode: 'VI' },
  { value: 2, label: 'Australia', currency: 'AUD', countryCode: 'AU' },
  // { id: 3, name: 'England' },
  // { id: 4, name: 'United States' },
  // { id: 5, name: 'Thailand' },
  // { id: 6, name: 'Japna' },
]

const Finance = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [addAccountModal, setAddAccountModal] = useState(false)
  const [creatingAccount, setCreatingAccount] = useState(false)
  const [created, setCreated] = useState(false)
  const [accountInfo, setAccountInfo] = useState<any>(null)
  const [accounts, setAccounts] = useState<any>([])
  const [transactionHistories, setTransactionHistories] = useState<any>([])
  const [isShowTransactionPopup, setShowTransactionPopup] = useState(false)
  const [countries, setCountries] = useState<any>([])
  const { t } = useTranslation("common")
  const alert = useAlert()

  const getTransactionHistories = (currency: string) => {
    client.get(`${END_POINT_V2.GET_TRANSACTIONS}?type=${currency}`)
      .then(res => {
        if (res.data?.errorCode === '00') {
          setTransactionHistories(res.data?.data)
        }
      })
  }

  const { profile } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    client.get(`${END_POINT_V2.GET_REGIONS}`)
      .then(res => {
        setCountries(res.data?.data)
      })

  }, [])

  useEffect(() => {
    if (profile?.main?.ekycStatus !== 2) {
      history.push('/orders')
    }
  }, [])

  const getAccounts = () => {
    client.get(`${END_POINT_V2.GET_ACCOUNTS}`)
      .then(res => {
        if (res.data?.errorCode === '00') {
          dataTemp.splice(0, res.data?.data.length, ...res.data?.data)
          console.log(dataTemp)
          setAccounts(dataTemp)
          if (dataTemp[0])
            getTransactionHistories(dataTemp[0].currency)
        }
      })
  }

  useEffect(() => {
    getAccounts()
  }, [])

  const onSubmit = async (val: any) => {
    setCreatingAccount(true)
    client.post(`${END_POINT_V2.CREATE_ACCOUNT}`, {
      "countryCode": val?.region?.code,
      "currency": val?.region?.currency,
      "nickName": val?.accountName,
    })
      .then(res => {
        setCreatingAccount(false)
        if (res.data?.errorCode === '00') {
          setCreated(true)
          setAccountInfo(res.data?.data)
          return;
        }
        setCreated(false)
        alert.error('Create account failed')

      })
      .catch(err => {
        setCreatingAccount(false)
        alert.error('Create account failed')

      })
    console.log(val)
  }

  if (process.env.REACT_APP_NODE_ENV === 'production' && process.env.REACT_APP_FINANCE_READY === 'false') {
    return <>{'COMING SOON'}</>
  }

  return (
    <>
      <div className=" bg-[#f4f4f4] p-6">
        <div className=" xl:container xl:m-auto grid grid-cols-12 gap-6">
          <div className=" col-span-12 md:col-span-5 xl:col-span-3">
            <Statistic setShowTransactionPopup={setShowTransactionPopup} accounts={accounts} />
            <MyCards onAddAccountModal={() => setAddAccountModal(true)} accounts={accounts} />
          </div>
          <div className="col-span-12  md:col-span-7 xl:col-span-9">
            <MyTransaction transactionHistories={transactionHistories} accounts={accounts} />
          </div>
        </div>
      </div>

      <Transition appear show={addAccountModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { setAddAccountModal(false) }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {created ? 'Your account info' : 'Add more account'}
                  </Dialog.Title>
                  {
                    created && <div className="mt-2 grid gap-4">
                      <div>
                        <div className="py-2 w-full items-end ">
                          <span className="font-bold"> Account number: </span> {accountInfo?.accountNumber}
                        </div>
                        <div className="py-2 w-full items-end ">
                          <span className="font-bold"> Account name: </span> {accountInfo?.nickName}
                        </div>
                        {
                          accountInfo?.currency === 'VND' && <div className="py-2 w-full items-end ">
                            <span className="font-bold"> Bank: </span> VPB
                          </div>
                        }

                        <div >
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={() => { getAccounts(); setAddAccountModal(false); setCreated(false); setAccountInfo(null); }}

                          > Confirm
                          </button>
                        </div>

                      </div>

                    </div>
                  }
                  {
                    !created && <form className=' w-full ' onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-2 grid gap-4">
                        <div>
                          <div className="py-2 w-full items-end ">
                            <label className=' text-xs mr-3' htmlFor="bank">{t("GENERAL.CHOOSE_REGION")}</label>
                            {/* @ts-ignore */}
                            <Controller
                              control={control}
                              name="region"
                              //@ts-ignore
                              {...register('region', {
                                validate: e => {
                                  console.log(e)
                                  if (!e)
                                    return `${t("GENERAL.CHOOSE_REGION")}`
                                  return null
                                }
                              })}
                              render={(field) => {
                                console.log(field.value)
                                return (
                                  <Select
                                    className=" font-normal text-xs"
                                    styles={customSelectStyle}
                                    hideSelectedOptions={false}
                                    value={field.value || []}
                                    options={countries}
                                    closeMenuOnSelect={true}
                                    menuPlacement="auto"
                                    placeholder={t("GENERAL.CHOOSE_REGION")}
                                    maxMenuHeight={200}
                                    isOptionDisabled={(option) => +option.status !== 1}
                                    getOptionLabel={(option) => {
                                      let str = ``
                                      if (+option.status === 2) {
                                        str = `(${t("GENERAL.COMING_SOON")})`
                                      }
                                      if (+option.status === 3) {
                                        str = `(${t("GENERAL.DISABLED")})`
                                      }
                                      return `${option.name} ${str}`
                                    }}
                                    getOptionValue={(option) => option._id}
                                    onChange={(e: any) => {
                                      field.onChange(e)
                                    }}
                                  />
                                )
                              }}
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs"
                              errors={errors}
                              name="region"
                              as="p"
                            />
                          </div>
                        </div>

                        <div className=''>
                          <label className=' text-xs' htmlFor="account-name">{t("ACCOUNT.ACCOUNT_NAME")}</label>
                          <input
                            className="border mt-1 text-xs py-3 px-2 rounded-md w-full focus:outline-none"
                            type="text"
                            id="account-name"
                            name="accountName"
                            placeholder={t("ACCOUNT.ENTER_ACCOUNT_NAME")}
                            ref={register({
                              validate: e => {
                                if (!e)
                                  return `${t("ACCOUNT.REQUIRED")}`
                              }
                            })
                            }
                          />
                          {errors.name && <p
                            className="text-red-500 text-xs"
                          >
                            {errors.name?.message}
                          </p>}
                        </div>

                        {/* <div className=''>
                          <label className=' text-xs' htmlFor="nickName">{t("ACCOUNT.NICK_NAME")}</label>
                          <input
                            className="border mt-1 text-xs py-3 px-2 rounded-md w-full focus:outline-none"
                            type="text"
                            id="nickName"
                            name="nickName"
                            placeholder={t("ACCOUNT.ENTER_NICK_NAME")}
                            ref={register({
                              validate: e => {
                                if (!e)
                                  return `${t("ACCOUNT.REQUIRED")}`
                              }
                            })
                            }
                          />
                          {errors.nickName && <p
                            className="text-red-500 text-xs"
                          >
                            {errors.nickName?.message}
                          </p>}
                        </div> */}
                      </div>

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          // onClick={() => createAccount()}
                          disabled={creatingAccount}
                        >
                          {
                            creatingAccount && <svg aria-hidden="true" className="w-6 h-6 mr-2  animate-spin text-white fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                          }

                          {
                            creatingAccount ? 'Creating' : 'Create Account'
                          }

                        </button>
                      </div>

                    </form>
                  }

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isShowTransactionPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setShowTransactionPopup(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed flex justify-center items-center z-40 inset-0  overflow-hidden">
            <div className="flex items-center justify-center md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              // m
              >
                <Dialog.Panel className="transform overflow-auto min-w-[50vh] max-w-[100vh] h-screen md:h-auto py-6 md:py-6 md:w-auto md:rounded-2xl bg-white px-6 text-left align-middle shadow-xl transition-all">
                  <div className='block md:hidden text-[#bababa] text-sm flex items-center py-4 cursor-pointer' onClick={() => setShowTransactionPopup(false)}>
                    <ArrowLongLeftIcon width={14} height={14} color="#bababa" />
                    <span className='ml-2'>Back</span></div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 p-3"
                  >
                    Transfer
                  </Dialog.Title>
                  <div className=' px-4 md:px-0 md:w-[40vw] md:max-w-[60vw] max-h-[80vh] min-h-[70vh] overflow-auto'>
                    <Tab.Group>
                      <Tab.List className="flex space-x-1 rounded-xl px-6 py-4">
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 ',
                              'ring-white ring-opacity-60 ring-offset-2  focus:outline-none ',
                              selected
                                ? 'bg-white shadow text-blue-500'
                                : 'text-black'
                            )
                          }>
                          {t("FINANCE.INTERNATIONAL")}
                        </Tab>
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              'w-full rounded-lg py-2.5 text-sm font-medium leading-5 ',
                              'ring-white ring-opacity-60 ring-offset-2 focus:outline-none ',
                              selected
                                ? 'bg-white shadow text-blue-500'
                                : 'text-black'
                            )
                          }>
                          {t("FINANCE.DOMESTIC")}
                        </Tab>
                      </Tab.List>
                      <Tab.Panels className="mt-2">
                        <Tab.Panel className={classNames(
                          'rounded-xl bg-white p-6',
                          'ring-white ring-opacity-60 ring-offset-2  focus:outline-none '
                        )}>
                          <International />
                        </Tab.Panel>
                        <Tab.Panel className={classNames(
                          'rounded-xl bg-white p-3',
                          'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none '
                        )}>
                          <div className="min-h-screen md:min-h-[300px] md:pt-8 px-4 md:px-10 overflow-auto">
                            <Domestic />
                          </div>
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  )
}

export default (Finance)