import { StylesConfig } from "react-select";

export const customSelectStyle: StylesConfig<any, true> = {
  clearIndicator: (base) => ({ ...base, display: 'none' }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  dropdownIndicator: (base) => ({ ...base, padding: 4, color: "#2E3A59", marginRight: 12 }),
  control: (styles) => ({
    ...styles,
    borderColor: '#EBEBF0',
    minHeight: '40px',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '8px',
    fontSize: 12,
    "focus": "ouline-none"
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#F2F9FF',
    borderRadius: '18px',
    textColor: '#2E3A59',
    fontWeight: 400,
  }),
}