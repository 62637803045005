/* eslint-disable react/react-in-jsx-scope */

import { ArrowDown } from "assets/icons/ArrowDown";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {FolderArrowDownIcon, DocumentTextIcon, ArrowsUpDownIcon} from '@heroicons/react/20/solid'
import { CheckMark } from "assets/icons/CheckMark";
import { numberWithCommas } from "utils/number";

const datatemp = [
  {
    title: "Figma Pro plan",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Subscription",
    users: "10"
  },
  {
    title: "Capshort Pro",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Receive",
    users: "10"
  },
  {
    title: "Figma Pro plan",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Subscription",
    users: "10"
  },
  {
    title: "Capshort Pro",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Receive",
    users: "10"
  },
  {
    title: "Figma Pro plan",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Subscription",
    users: "10"
  },
  {
    title: "Capshort Pro",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Receive",
    users: "10"
  },
  {
    title: "Figma Pro plan",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Subscription",
    users: "10"
  },
  {
    title: "Capshort Pro",
    billing_date: "11/12/2022",
    amount: "USD 1000$",
    type: "Receive",
    users: "10"
  }
]

const Table = ({data = []}: any) => {
  const {t} = useTranslation("common")
  return (
      <div className="max-w--xl overflow-auto">
        <table className="shadow-md rounded-md w-full mt-6">
          <thead className=" border-b border-slate-200">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md w-[420px]">
                <div className="flex items-center text-[#bababa]"> <span className="mr-2">Transaction date</span> <ArrowsUpDownIcon width={14} height={14} color="#bababa"/> </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900 rounded-tl-md flex items-center">
                <span className="mr-1 text-[#bababa]">Content</span> 
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                <div className="flex items-center text-[#bababa]"> <span className="mr-2">Amount</span> <ArrowsUpDownIcon width={14} height={14} color="#bababa"/> </div>
              </th>
              <th className="px-6 py-3 text-left text-sm font-medium text-slate-900">
                <span className="mr-1 text-[#bababa]">Withdraw fee</span> 
              </th>
            </tr>
          </thead>
          {
            data.length > 0 &&
            <tbody>
            {
              (data).map((transaction: any) => {
                return (
                  <tr key={transaction?.id} className="">
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[420px] flex items-center">
                      {moment.unix(transaction?.created).format("DD/MM/YYYY HH:mm")}
                    </td>
                    <td className="px-6 py-4 text-sm text-slate-900">
                      {transaction?.description}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis max-w-[380px]">
                      {numberWithCommas(transaction?.amount)}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium text-slate-900 truncate text-ellipsis capitalize">
                      {numberWithCommas(transaction?.metadata?.withdraw_fee)}
                    </td>
                  </tr>
                );
              })
            }
            </tbody>
          }
          
        </table>
          {
            !data.length && <div className="w- py-6 text-center text-[#bababa] text-sm">No data</div>
          }
      </div>
  );
};

export default Table;
